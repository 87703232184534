var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "drawer-container" }, [
    _c("div", { staticClass: "drawer-body1" }, [
      _c(
        "div",
        { staticClass: "goods-table-wrapper" },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                stripe: "",
                size: "mini",
                "element-loading-text": "拼命加载中",
                "header-cell-style": { background: "#e5f2ff" },
                sortable: "",
                "highlight-current-row": "",
                "header-cell-class-name": "normal-table-header",
                data: _vm.tableData,
                "show-summary": _vm.showSummary,
                "summary-method": _vm.summaryMethod
              },
              on: { "current-change": _vm.onCurrentChange }
            },
            [
              _vm._l(_vm.columns, function(item) {
                return [
                  _c("el-table-column", {
                    key: item.label,
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: item.label,
                      prop: item.prop
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              item.number
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm._f("decimal2")(row[item.prop]))
                                    )
                                  ])
                                : _c("span", [_vm._v(_vm._s(row[item.prop]))])
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ]
              })
            ],
            2
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }