<template>
  <div class="drawer-container">
      <div class="drawer-body1">
        <div class="goods-table-wrapper">
          <el-table
            style="width: 100%;"
            border
            stripe
            size="mini"
            element-loading-text="拼命加载中"
            :header-cell-style="{ background: '#e5f2ff' }"
            sortable
            highlight-current-row
            header-cell-class-name="normal-table-header"
            :data="tableData"
            @current-change="onCurrentChange"
            :show-summary="showSummary"
            :summary-method="summaryMethod"
            >
            <template v-for="item of columns">
              <el-table-column :show-overflow-tooltip="true"
                :key="item.label"
                :label="item.label"
                :prop="item.prop"
              >
              <template v-slot:default="{row}">
                <span v-if="item.number">{{ row[item.prop] | decimal2}}</span>
                <span v-else>{{ row[item.prop]}}</span>
              </template>
              </el-table-column>
            </template>
          </el-table>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "ReceiveItemTable",
  props: {
    visible: Boolean,
    goodsForm: Object,
    goodsFormRules: Object,
    goodsFormItems: Array,
    tableData: Array,
    businessId: [String, Number],
    orderTable: Object,
    columns:Array,
    // 显示合计
    showSummary: {
      type: Boolean,
      default: false,
    },
    summaryMethod: {
      type: Function,
      defalut: null
    }
  },

  data() {
    return {
      currentRadioIndex: "",
      goodsCateModalVisible: false,
      GOODS_STATUS_MAP: {
        0: { label: "正常", type: "success" },
        1: { label: "异常", type: "error" },
      },
      currentRow: {},
    };
  },
  methods: {
    onCurrentChange(row) {
      this.currentRow = row;
    },
  },
  computed: {
    projectList() {
      return this.$store.state.workbench.projectList;
    },
    goodsRank() {
      return this.$store.state.workbench.goodsRank;
    },
    goodsTypes() {
      return this.$store.state.workbench.goodsTypes;
    },
  },
  watch: {
    visible: {
      // async handler(val) {
      //   if (!val) return;
      //   this.getTableData();
      //   await Promise.all([
      //     this.$store.dispatch("getProjectList"),
      //     this.$store.dispatch("getGoodsRank"),
      //     this.$store.dispatch("getGoodsTypes"),
      //   ]);
      //   for (const item of this.goodsFormItems) {
      //     if (item.prop === "productId") {
      //       item.options = this.projectList.map((item) => ({
      //         label: item.fullName,
      //         value: item.id,
      //       }));
      //     } else if (item.prop === "goodsRank") {
      //       item.options = this.goodsRank;
      //     } else if (item.prop === "goodsTypeId") {
      //       item.options = this.goodsTypes.map((item) => ({
      //         value: item.id,
      //         label: item.name,
      //       }));
      //     }
      //   }
      // },
    },
  },
};
</script>

<style lang="scss" scoped>
.drawer-container {
  .drawer-body1 {
    height: 100%;
    display: flex;
    flex-direction: column;
    .tableSearch {
      padding: 0 30px;
      .action-btns {
        > .el-form-item {
          ::v-deep .el-form-item__label {
            width: 0px !important;
          }
        }
      }
    }
    .goods-table-wrapper {
      flex: 1;
      width: 100%;
      ::v-deep .el-radio__label {
        display: none;
      }
    }
    ::v-deep .el-radio__label {
      display: none;
    }
  }
  .drawer-footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: center;
    background-color: white;
  }
}
::v-deep .el-radio__label {
  display: none;
}
.el-radio__label {
  display: none;
}
.drawer-enter-active,
.drawer-leave-active {
  transition: 0.3s;
}
.drawer-enter {
  transform: translate(100%);
}
.drawer-leave-to {
  transform: translate(100%);
}
</style>
